<template>
  <router-link
      :to="{ name: TOURNAMENT.attributes.participant_type === 'TEAM' ? 'team.name' : 'another-profile', params: { id: item.id }}"
      target="_blank"
      class="team-card"
      :class="{segregate: isTournamentStarted && (item.entrie.status !== 'confirmed')}"
  >
    <div class="team-card__info">
      <div class="logo"><img :src="item.attributes.logo" alt="#"></div>
      <div class="team-name">{{ TOURNAMENT.attributes.participant_type === 'TEAM' ? item.attributes.name : item.attributes.login }}</div>
    </div>
<!--    <div class="team-card__status">{{status === '' ? 'Присоединились' : 'Ожидается подтверждение'}}</div>-->
    <div class="team-card__status">{{getStatusParticipant}}</div>
  </router-link>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "teamCard",
  props: {
    item: {
      type: Object,
    },
    status: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters([
        'TOURNAMENT'
    ]),

    isTournamentStarted () {
      const {status} = this.TOURNAMENT.attributes;
      const validStatuses = ['before_start', 'in_process', 'finished'];

      return validStatuses.some(validStatus => status === validStatus);
    },

    getParticipant() {
      const statuses = {
        'USER': (this.item.entrie.status === 'confirmed') && this.isTournamentStarted ? 'Присоединился' : 'Не подтвержден',
        'TEAM': (this.item.entrie.status === 'confirmed') && this.isTournamentStarted ? 'Присоединилась' : 'Не подтверждена'
      }

      const participantType = this.TOURNAMENT.attributes['participant_type'];
      return statuses[participantType]
    },

    getStatusParticipant() {
      return this.getParticipant
    }
  },
}
</script>

<style scoped>

</style>
